.footer-container{
    background-color: white;
    display: flex;
    justify-content: space-evenly;
}

.footer-screen{
    margin-right: auto;
    margin-left: auto;
    max-width: 1280px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
    max-height: 80vh;
    height: 80px;
    @media (min-width: 640px) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    
      @media (min-width: 1024px) {
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 6rem;
        
      }
}


.footer-grid{
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 8px;
  
    @media (min-width: 1024px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

}


.footer-saying{
    margin-top: 1.5rem;
  max-width: 28rem; /* You may adjust the value based on your design */
  text-align: center;
  line-height: 1.75;
  color: #718096;

  @media (min-width: 640px) {
    max-width: 20rem;
    text-align: left;
  }

}

.footer-col-2{
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  gap: 1.5rem;

  @media (min-width: 640px) {
    justify-content: start;
    gap: 2rem;
  }

  @media (min-width: 768px) {
    gap: 2.5rem;
  }

}

.footer-icon-container{
    color: #007b5e; /* Replace with the desired teal color */
  transition: color 0.3s ease; /* Add a smooth transition effect */

  &:hover {
    color: rgba(0, 123, 94, 0.75); /* Adjust the opacity or color as needed */
  }
  display: flex;
  justify-content: center;
  text-align: center;

}

.icon-name{
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;

}

.footer-icon{
    height: 1.5rem; 
    width: 1.5rem;
    
}


.footer-contact-container{
    text-align: center; 
}

@media (min-width: 640px) {
  .custom-text-alignment {
    text-align: left;
  }

}

.footer-contact-p{
    font-size: 1.125rem; /* Equivalent to text-lg in Tailwind CSS */
  font-weight: 500; /* Equivalent to font-medium in Tailwind CSS */
  color: #1a202c;
}

.contact-list{
    margin-top: 2rem;
    margin-bottom: 1rem;


}

.contact-list p{
    font-size: 0.875rem;
}

.contact-list-item{
    display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.375rem;
}

.contact-list-item.ltr-sm{
    justify-content: flex-start;

}

.contact-list-item.rtl-sm{
    justify-content: flex-end;

}

.footer-email{
    flex: 1;
    color: #4a5568 ;
}

.footer-number{
    flex: 1;
    color: #4a5568 ;
}

.footer-address{
    margin-top: -0.125rem;
    flex: 1;
    font-style: normal; /* Equivalent to not-italic in Tailwind CSS */
    color: #4a5568; 
}

.contact-svg{
    flex-shrink: 0;
    color: #1a202c;
    width: 20px;
  height: 20px;
  text-decoration: none;
}
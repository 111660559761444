.active{
    border-bottom: black 0.08em solid ;
}

nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F0E0C5;
    position: sticky;
    top: 0;
    filter: drop-shadow(5px 5px 1px rgba(129, 128, 128, 0.25));

}
.navbar{
    background-color: #F0E0C5;
    z-index: 1000;
}

.nav-logo{
    width: 30px;
    height: 30px;
}


nav .navbar-logo{
    font-size: 40;
    font-family: "Merriweather Sans", sans-serif;
    font-weight: 400;
    font-style: bold;
    color: black;
    text-decoration: none;
    margin: 1rem;
    position: relative;
    left: 30px;
}


nav ul{
    display: flex;

}

nav ul li{
    list-style: none;
    
}

nav ul li a{
    display: block;
    text-decoration: none;
    color: #AB7E33;
    font-family: "Merriweather Sans", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 15px;
    padding: 0.5rem;
    margin: 0 2rem;
    border: solid #AB7E33 1px;
    border-radius: 15px;
    position: relative;
    right: 40px;
}

nav ul li a:hover{
    color: grey;
    border-bottom: #AB7E33 solid 1px;
    background-color: white;
}

nav .menu{
    display: none;
    position: absolute;
    top: 0.75rem;
    right: 0.5rem;
    flex-direction: column;
    justify-content: space-between;
    width: 2.25rem;
    height: 2rem;
}

nav .menu span{
    height: 0.3rem;
    width: 100%;
    background-color: white;
    border-radius: 0.1rem;

}


@media (max-width: 480px){
    nav .menu{
        display: flex;
    }
    nav{
        flex-direction: column;
        align-items: start;
    }

    nav ul{
        display: none;
        flex-direction:column ;
        width: 100%;
        margin-bottom: 0.25rem
    }

    nav ul.open{
        display: flex;
    }

    nav ul li{
        width: 100%;
        text-align: center;
    }
    nav ul li a{
        margin: 0.2rem 0.5rem;
    }
}

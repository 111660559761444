

.contactp-section{
    font-family: "Poppins", sans-serif;
    background: linear-gradient(#e5af52, #fff7f9) ;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.contactp-container{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.contactp-left{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

}

.contactp-left-title h2{
    font-weight: 600;
    color: #825b18;
    font-size: 40px;
    margin-bottom: 5px;
}

.contactp-left-title hr{
    border: none;
    width: 120px;
    height: 5px;
    background-color:#825b18 ;
    border-radius: 10px;
    margin-bottom: 20px;
   
}

.contactp-inputs{
    width: 400px;
    height: 45px;
    border: none;
    outline: none;
    padding-left: 25px;
    font-weight: 500px;
    color: #666;
    border-radius: 10px;
}

.contactp-left textarea{
    height: 140px;
    padding-top: 15px;
    border-radius: 8px;
}

.contactp-inputs:focus{
    border: 2px solid #ff994f;
}

.contactp-inputs::placeholder{
    color: lightgray;

}

.contactp-left button{
    display: flex;
    align-items: center;
    padding: 15px 30px;
    font-size: 16px;
    color: white;
    border: none;
    border-radius: 10px;
    background: linear-gradient(270deg, #e5af52, #E8B45E) ;
    cursor: pointer;
    border: 0.5px solid #825b18;
}

.contactp-left button:hover{
    background-color: #fcd34d;
    color: #AB7E33;
}



.contactp-left button img{
    height: 15px;
    margin-left: 10px;
    margin-bottom: 3px;
    transition: transform 0.3s ease-in-out;

}

.contactp-right{
    width: 400px;
    height: 400px;
    background-color:#e5af52 ;
    border-radius: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
    border: 0.5px solid #825b18;

}

.contactp-right img{
    width: 300px;
}

@media(max-width:800px){
    .contactp-inputs{
        width: 80vw;
    }
    .contactp-right{
        display: ;
    }
}